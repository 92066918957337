<template>
  <div class="appointment-card">
    <div class="appointment-card__container">
      <div class="appointment-card__block" @click="goToVisitDetails(appointmentsData.id)">
        <div class="appointment-card__label">
          {{ appointmentsData?.type?.title }}
        </div>
        <span class="appointment-card__time">{{ appointmentsData?.starts_date_time.slice(11, 16) }} - {{ appointmentsData?.finish_date_time.slice(11, 16) }}</span>
        <div class="appointment-card__image">
          <img
            v-if="appointmentsData?.unit.photo"
            :src="appointmentsData?.unit.photo"
            alt=""
          >
          <img
            v-else
            src="../../../assets/image/lock.png"
            alt=""
          >
          <div class="appointment-card__status">
            <div class="appointment-card__tools-group">
              <div
                class="appointment-card__tools"
                v-if="isCallRequest"
              >
                <Phone
                  @click.stop="saveCallRequest(appointmentsData.id)"
                  class="appointment-card__phone"
                  v-if="appointmentsData.call_check"
                />
                <Phone
                  @click.stop="saveCallRequest(appointmentsData.id)"
                  v-if="!appointmentsData.call_check"
                />
              </div>
              <div class="appointment-card__tools">
                <Delete @click.stop="deleteAppointment(appointmentsData?.id)" />
              </div>
            </div>
          </div>
        </div>
        <div class="appointment-card__block-info">
          <div class="appointment-card__name">
            <span class="appointment-card__title-group">{{$t('message.property.unit_name')}}:</span>
            {{ appointmentsData?.unit?.name }}
          </div>
          <div class="appointment-card__visitor">
            <span class="appointment-card__title-group">{{$t('message.dashboard.visitor')}}:</span>
            {{ appointmentsData?.seeker?.first_name }} {{ appointmentsData?.seeker?.last_name }}, {{ appointmentsData?.seeker?.phone.value }}
          </div>
          <div class="appointment-card__address">
            <span class="appointment-card__title-group">{{$t('message.building.address')}}:</span>
            {{ appointmentsData?.unit?.address }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Delete from '@/assets/svg/delete.svg?inline'
import Phone from '@/assets/svg/phone.svg?inline'

export default {
  name: 'AppointmentCard',
  components: {
    Delete,
    Phone
  },
  props: {
    appointmentsData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deleteAppointment: {
      type: Function,
      default: () => {
        return false
      }
    },
    saveCallRequest: {
      type: Function,
      default: () => {
        return false
      }
    },
    isCallRequest: Boolean
  },
  methods: {
    goToVisitDetails (id) {
      this.$router.push('/dashboard/visit-details/' + id)
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment-card {
  &__container {
    padding: 40px 10px 5px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 190px;
    overflow: hidden;
    background: #ffffff;
    z-index: 0;
    position: relative;
    border: 1px solid #d2d4d8;
    border-radius: 15px 8px 15px 15px;
    overflow: hidden;

    &:hover {
      height: 100%;
      z-index: 1;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    }

    svg {
      margin-top: 20px;
    }
  }

  &__image {
    display: flex;
    flex-direction: column;
    img {
      max-width: 76px;
      max-height: 92px;
      object-fit: contain;
    }
  }

  &__block {
    display: flex;

    &:hover {
      cursor: pointer;
    }
  }

  &__appointment-card_name {
    font-size: 14px;
    color: #8c8c8c;
    padding: 10px 0;
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    background: #414141;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    padding: 5px 10px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-width: 80px;
  }

  &__phone {
    ::v-deep(circle) {
      fill: #678659;
    }
    ::v-deep(path) {
      fill: #ffffff;
    }
  }

  &__block-info {
    text-align: left;
    padding-left: 10px;
    height: 90%;
    overflow: hidden;
  }

  &__address {
    font-size: 14px;
    overflow: hidden;
  }

  &__time {
    display: inline-block;
    background: #8cb4b1;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    padding: 3px 5px;
    color: #ffffff;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__visitor {
    width: 100%;
    font-size: 14px;
    text-align: left;
    padding-bottom: 8px;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;

    svg {
      width: 24px;
      height: 24px;
      margin: 0 7px;

      &:hover {
        ::v-deep(circle) {
          fill: #eff8eb;
        }
      }
    }
  }

  &__name {
    font-size: 14px;
    max-width: 170px;
    padding-bottom: 8px;
  }
  &__title-group {
    color: #8c8c8c;
    padding-right: 3px;
  }

  &__tools-group {
    display: flex;
    padding-top: 15px;
  }

  &__tools {
    svg {
      cursor: pointer;
    }
  }
  &__container {
    height: auto;
  }
  &__block-info {
    height: 100%;
  }
}
@media (max-width: 1500px) {
  .appointment-card {
  }
}
</style>
