import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b87c5608"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "dashboard__top-bar" }
const _hoisted_3 = { class: "dashboard__title" }
const _hoisted_4 = { class: "dashboard__visits-container-wrap" }
const _hoisted_5 = { class: "dashboard__visits-wrap" }
const _hoisted_6 = { class: "dashboard__filter" }
const _hoisted_7 = { class: "dashboard__filter-items" }
const _hoisted_8 = { class: "dashboard__filter-all" }
const _hoisted_9 = {
  key: 0,
  class: "dashboard__appointment-card-container"
}
const _hoisted_10 = {
  key: 0,
  class: "dashboard__no-visit"
}
const _hoisted_11 = { class: "dashboard__no-visit-text" }
const _hoisted_12 = { class: "dashboard__appointment-date-group" }
const _hoisted_13 = { class: "dashboard__appointment-date" }
const _hoisted_14 = { class: "dashboard__visits-container" }
const _hoisted_15 = {
  key: 1,
  class: "dashboard__appointment-card-container"
}
const _hoisted_16 = {
  key: 0,
  class: "dashboard__no-visit"
}
const _hoisted_17 = { class: "dashboard__no-visit-text" }
const _hoisted_18 = { class: "dashboard__appointment-date-group" }
const _hoisted_19 = { class: "dashboard__appointment-date" }
const _hoisted_20 = { class: "dashboard__visits-container" }
const _hoisted_21 = { class: "dashboard__botton-bar" }
const _hoisted_22 = { class: "dashboard__title" }
const _hoisted_23 = {
  key: 0,
  class: "dashboard__no-smart-devices"
}
const _hoisted_24 = { class: "dashboard__no-visit-text" }
const _hoisted_25 = {
  key: 1,
  class: "dashboard__no-search"
}
const _hoisted_26 = { class: "dashboard__modal" }
const _hoisted_27 = { class: "dashboard__modal-text" }
const _hoisted_28 = { class: "dashboard__modal-buttons" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "dashboard__modal" }
const _hoisted_31 = { class: "dashboard__modal-text" }
const _hoisted_32 = { class: "dashboard__modal-buttons" }
const _hoisted_33 = { class: "dashboard__modal" }
const _hoisted_34 = { class: "dashboard__modal-text" }
const _hoisted_35 = { class: "dashboard__modal-buttons" }
const _hoisted_36 = { class: "dashboard__modal" }
const _hoisted_37 = { class: "dashboard__modal-text" }
const _hoisted_38 = { class: "dashboard__modal-buttons" }
const _hoisted_39 = { class: "dashboard__modal" }
const _hoisted_40 = { class: "dashboard__modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LottieAnimation = _resolveComponent("LottieAnimation")!
  const _component_SearchContainer = _resolveComponent("SearchContainer")!
  const _component_NoVisitYet = _resolveComponent("NoVisitYet")!
  const _component_AppointmentCard = _resolveComponent("AppointmentCard")!
  const _component_SmartDevices = _resolveComponent("SmartDevices")!
  const _component_NoSmartDevicesYet = _resolveComponent("NoSmartDevicesYet")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_NoSearchIcon = _resolveComponent("NoSearchIcon")!
  const _component_AlertBlock = _resolveComponent("AlertBlock")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LottieAnimation, {
          key: 0,
          class: "dashboard__animation",
          animationData: require('@/assets/preloader/load.json')
        }, null, 8, ["animationData"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('message.dashboard.my_appointments')), 1),
        _createVNode(_component_SearchContainer, {
          "search-data": this.searchDataAppointments,
          placeholderData: _ctx.$t('message.dashboard.search_appointments'),
          "search-function": this.getAppointments,
          class: "dashboard__appointments-search"
        }, null, 8, ["search-data", "placeholderData", "search-function"]),
        _createElementVNode("div", {
          class: "dashboard__create-slot second-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToCreateSlots()))
        }, _toDisplayString(_ctx.$t('message.dashboard.create_slots')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFilter('false'))),
                class: _normalizeClass(['dashboard__filter-item', _ctx.filter_visits == 'false' && 'active'])
              }, _toDisplayString(_ctx.$t('message.dashboard.upcoming')), 3),
              _createElementVNode("div", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleFilter('true'))),
                class: _normalizeClass(['dashboard__filter-item', _ctx.filter_visits == 'true' && 'active'])
              }, _toDisplayString(_ctx.$t('message.dashboard.history')), 3)
            ]),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('message.dashboard.all_planned')) + " " + _toDisplayString(this.appointmentsData.total), 1)
          ]),
          (_ctx.filter_visits == 'false')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (this.appointmentsData?.data?.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_NoVisitYet),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('message.dashboard.no_visits_yet')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.appointmentsData.data, (item, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", null, _toDisplayString(key), 1),
                        _createElementVNode("div", null, _toDisplayString(item.visits.length), 1)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.visits, (visit) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: visit.id,
                            class: "dashboard__appointment-card"
                          }, [
                            _createVNode(_component_AppointmentCard, {
                              "appointments-data": visit,
                              "delete-appointment": _ctx.openDeleteAppointments,
                              "save-call-request": _ctx.saveCallRequestPopup,
                              "is-call-request": true
                            }, null, 8, ["appointments-data", "delete-appointment", "save-call-request"])
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.filter_visits == 'true')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                (this.appointmentsData?.data?.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_NoVisitYet),
                      _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('message.dashboard.no_historic_visits')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.appointmentsData.data, (item, key) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", null, _toDisplayString(key), 1),
                        _createElementVNode("div", null, _toDisplayString(item.visits.length), 1)
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.visits, (visit) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: visit.id,
                            class: "dashboard__appointment-card"
                          }, [
                            _createVNode(_component_AppointmentCard, {
                              "appointments-data": visit,
                              "delete-appointment": _ctx.openDeleteAppointmentsHistory
                            }, null, 8, ["appointments-data", "delete-appointment"])
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('message.dashboard.my_smart_devices')), 1),
        _createVNode(_component_SearchContainer, {
          "search-data": this.searchData,
          placeholderData: _ctx.$t('message.dashboard.search_device'),
          "search-function": this.getDevices
        }, null, 8, ["search-data", "placeholderData", "search-function"]),
        _createElementVNode("div", {
          class: "dashboard__refresh-button second-button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.refreshDevices()))
        }, _toDisplayString(_ctx.$t('message.refresh')), 1)
      ]),
      _createVNode(_component_SmartDevices, {
        devices: _ctx.devicesData,
        "open-device": _ctx.openDevice,
        closeDevice: _ctx.closeDevice
      }, null, 8, ["devices", "open-device", "closeDevice"]),
      (this.devicesData?.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createVNode(_component_NoSmartDevicesYet),
            _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('message.dashboard.no_smart_devices_yet')), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Pagination, {
        propertyMeta: _ctx.visitMeta,
        propertyList: _ctx.appointmentsData,
        page: _ctx.page,
        handlePage: _ctx.handlePageVisit
      }, null, 8, ["propertyMeta", "propertyList", "page", "handlePage"]),
      (this.appointmentsData.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
            _createVNode(_component_NoSearchIcon)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isModalDelete)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 2,
            "on-close": _ctx.handleCloseModalDelete
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('message.dashboard.delete_appointments')), 1),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", {
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDelete && _ctx.handleCloseModalDelete(...args))),
                    class: "dashboard__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.cancelAppointments && _ctx.cancelAppointments(...args))),
                    class: "dashboard__modal-button second-button"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('message.delete')) + " ", 1),
                    (this.errorDeleteBuilding)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                          _createVNode(_component_AlertBlock, {
                            message: this.errorDeleteBuilding.unit
                          }, null, 8, ["message"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (this.isModalCallRequest)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 3,
            "on-close": _ctx.handleCloseModalCallRequest
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('message.are_you_sure')), 1),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", {
                    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalCallRequest && _ctx.handleCloseModalCallRequest(...args))),
                    class: "dashboard__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.saveCallRequest && _ctx.saveCallRequest(...args))),
                    class: "dashboard__modal-button second-button"
                  }, _toDisplayString(_ctx.$t('message.yes')), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (this.isModalDeleteHistory)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 4,
            "on-close": _ctx.handleCloseModalDeleteHistory
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.$t('message.are_you_sure')), 1),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", {
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleCloseModalDeleteHistory && _ctx.handleCloseModalDeleteHistory(...args))),
                    class: "dashboard__modal-button third-button"
                  }, _toDisplayString(_ctx.$t('message.cancel')), 1),
                  _createElementVNode("div", {
                    onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.deleteAppointment && _ctx.deleteAppointment(...args))),
                    class: "dashboard__modal-button second-button"
                  }, _toDisplayString(_ctx.$t('message.yes')), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (this.errorDevicesOpenPopup)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 5,
            "on-close": _ctx.handleCloseDevicesOpenPopup
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("div", _hoisted_37, _toDisplayString(this.errorDevicesOpen), 1),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", {
                    class: "dashboard__modal-button second-button",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handleCloseDevicesOpenPopup && _ctx.handleCloseDevicesOpenPopup(...args)))
                  }, _toDisplayString(_ctx.$t('message.ok')), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true),
      (this.errorRefreshDevice !== null)
        ? (_openBlock(), _createBlock(_component_DefaultPopup, {
            key: 6,
            "on-close": _ctx.handleCloseRefreshDevicePopup
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_39, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.errorRefreshDevice, (message, email) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "dashboard__modal-text",
                    key: email
                  }, _toDisplayString(email) + ": " + _toDisplayString(message), 1))
                }), 128)),
                _createElementVNode("div", _hoisted_40, [
                  _createElementVNode("div", {
                    class: "dashboard__modal-button second-button",
                    onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.handleCloseRefreshDevicePopup && _ctx.handleCloseRefreshDevicePopup(...args)))
                  }, _toDisplayString(_ctx.$t('message.ok')), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["on-close"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}