
import { defineComponent } from 'vue'
import Info from '@/assets/svg/info.svg?inline'
import More from '@/assets/svg/more.svg?inline'

export default defineComponent({
  name: 'SmartDevices',
  data () {
    return {
      publish: false
    }
  },
  props: {
    devices: {
      type: Object,
      default: () => {
        return {}
      }
    },
    openDevice: {
      type: Function,
      default: () => {
        return false
      }
    },
    closeDevice: {
      type: Function,
      default: () => {
        return false
      }
    },
    handleChooseBuilding: {
      type: [Function, Boolean],
      default: false
    },
    handleGoToBuilding: {
      type: [Function, Boolean],
      default: false
    }
  },
  components: {
    Info,
    More
  },
  methods: {
    goToCopy (id) {
      this.$router.push(`/admin/create-building?id=${id}`)
    },
    createUnit (id) {
      this.$router.push(`/admin/create-unit?buildingId=${id}`)
    },
    createSinglUnit (id) {
      this.$router.push(`/admin/add-property-unit-information?buildingId=${id}&unitId=${'newUnit'}`)
    }
  }
})
