
import { defineComponent } from 'vue'
import SmartDevices from '@/components/molecules/table/SmartDevicesTable.vue'
import AppointmentCard from '@/components/molecules/card/AppointmentCard.vue'
import SearchContainer from '@/components/molecules/SearchContainer.vue'
import NoSearchIcon from '@/assets/svg/no-search.svg?inline'
import NoVisitYet from '@/assets/svg/no-visit-yet.svg?inline'
import NoSmartDevicesYet from '@/assets/svg/no-smart-devices-yet.svg?inline'
import Pagination from '@/components/molecules/Pagination.vue'
import { Vue3Lottie } from 'vue3-lottie'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'DashboardSection',
  data () {
    return {
      filter_visits: 'false',
      errorDevices: null,
      visitMeta: null,
      page: 1,
      devicesData: {},
      appointmentsData: {},
      searchData: '',
      searchDataAppointments: '',
      isLoading: true,
      isModalDelete: false,
      deleteAppointmentsId: null,
      visitId: null,
      isModalCallRequest: false,
      isModalDeleteHistory: false,
      deleteApointmentHistoryId: null,
      errorDevicesOpen: null,
      errorDevicesOpenPopup: false,
      errorRefreshDevice: null
    }
  },
  watch: {
    searchData: {
      deep: true,
      handler () {
        if (this.searchData.length > 1 || this.searchData.length === 0) {
          // this.page = 1
          this.getDevices()
        }
      },
      immediate: true
    },
    searchDataAppointments: {
      deep: true,
      handler () {
        if (this.searchDataAppointments.length > 1 || this.searchDataAppointments.length === 0) {
          this.getAppointments()
        }
      },
      immediate: true
    }
  },
  computed: {},
  components: {
    NoSearchIcon,
    SmartDevices,
    AppointmentCard,
    SearchContainer,
    Pagination,
    LottieAnimation: Vue3Lottie,
    DefaultPopup,
    AlertBlock,
    NoVisitYet,
    NoSmartDevicesYet
  },
  methods: {
    handleSearch (name) {
      if (name === 'trad') {
        this.getVisitsTrad()
      } else if (name === 'smart') {
        this.getVisitsSmart()
      }
    },
    handleFilter (value) {
      this.filter_visits = value
      this.getAppointments()
      this.isLoading = true
    },
    getDevices (searchData) {
      let query = `?page=${this.page}&n=5`
      if (searchData) {
        query = `?search=${searchData}&page=${this.page}&n=5`
      }
      this.$store.dispatch('devices/getDevices', query).then(
        (res) => {
          this.devicesData = res.data
          this.visitMeta = res.meta
        },
        (error) => {
          this.errorDevices = error.response
        }
      )
    },
    getAppointments (searchDataAppointments) {
      let query = `?group[date]=true&n=100&filter[history]=${this.filter_visits}`
      if (searchDataAppointments) {
        query = `?search=${searchDataAppointments}&group[date]=true&n=100&filter[history]=${this.filter_visits}`
      }
      this.$store.dispatch('devices/getAppointments', query).then(
        (res) => {
          this.appointmentsData = res
          this.isLoading = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    saveCallRequestPopup (visitId) {
      this.visitId = visitId
      this.isModalCallRequest = true
    },
    handleCloseModalCallRequest () {
      this.isModalCallRequest = false
    },
    handleCloseDevicesOpenPopup () {
      this.errorDevicesOpenPopup = false
    },
    saveCallRequest () {
      this.isLoading = true
      this.$store.dispatch('devices/saveCallRequest', this.visitId).then(
        () => {
          this.getAppointments()
          this.isModalCallRequest = false
        },
        (error) => {
          this.errorDevices = error.response
        }
      )
    },
    openDevice (deviceId) {
      this.$store.dispatch('devices/openDevice', deviceId).then(
        () => {
          this.getDevices()
        },
        (error) => {
          this.errorDevicesOpenPopup = true
          this.errorDevicesOpen = error.response.data.message
        }
      )
    },
    closeDevice (deviceId) {
      this.$store.dispatch('devices/closeDevice', deviceId).then(
        () => {
          this.getDevices()
        },
        (error) => {
          this.errorDevicesOpenPopup = true
          this.errorDevices = error.response.data.message
        }
      )
    },
    goToCreateSlots () {
      this.$router.push('/dashboard/choose-unit')
    },
    cancelAppointments () {
      this.isLoading = true
      this.$store.dispatch('devices/cancelAppointments', this.deleteAppointmentsId).then(
        () => {
          this.getAppointments()
          this.isModalDelete = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    deleteAppointment () {
      this.isLoading = true
      this.$store.dispatch('devices/deleteAppointments', this.deleteApointmentHistoryId).then(
        (res) => {
          this.getAppointments()
          this.isModalDeleteHistory = false
          this.isLoading = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    refreshDevices () {
      this.$store.dispatch('devices/refresh').then(
        (res) => {
          console.log(res)
          this.getDevices()
        },
        (error) => {
          console.log(error.response.data)
          this.errorRefreshDevice = error.response.data.exceptions
        }
      )
    },
    openDeleteAppointmentsHistory (id) {
      this.isModalDeleteHistory = true
      this.deleteApointmentHistoryId = id
    },
    handleCloseModalDeleteHistory () {
      this.isModalDeleteHistory = false
    },
    handlePageVisit (page) {
      this.page = page
      this.getDevices()
    },
    openDeleteAppointments (visitId) {
      this.deleteAppointmentsId = visitId
      this.isModalDelete = true
    },
    handleCloseModalDelete () {
      this.isModalDelete = false
    },
    handleCloseRefreshDevicePopup () {
      this.errorRefreshDevice = null
    }
  }
})
