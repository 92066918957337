import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff4905ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "smart-devices" }
const _hoisted_2 = { class: "smart-devices__svg-hint" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "smart-devices__action-group" }
const _hoisted_5 = {
  key: 0,
  class: "smart-devices__not-connection"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick", "disabled"]
const _hoisted_8 = { class: "smart-devices__mobile-more" }
const _hoisted_9 = { class: "smart-devices__mobile-more-wrap" }
const _hoisted_10 = { class: "smart-devices__mobile-more-container" }
const _hoisted_11 = { class: "smart-devices__mobile-more-left-block" }
const _hoisted_12 = { class: "smart-devices__mobile-title" }
const _hoisted_13 = { class: "smart-devices__mobile-value" }
const _hoisted_14 = { class: "smart-devices__mobile-title" }
const _hoisted_15 = { class: "smart-devices__mobile-value" }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { class: "smart-devices__mobile-buttons-group" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["onClick", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Info = _resolveComponent("Info")!
  const _component_More = _resolveComponent("More")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.property.building_address')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.dashboard.name')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.dashboard.barrier_title')), 1),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('message.dashboard.device_type')), 1),
          _createElementVNode("th", null, [
            _createTextVNode(_toDisplayString(_ctx.$t('message.actions')) + " ", 1),
            _createVNode(_component_Info, { class: "smart-devices__info" }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('message.actions_hint')), 1)
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.id
          }, [
            _createElementVNode("td", null, _toDisplayString(item.building_address), 1),
            _createElementVNode("td", null, _toDisplayString(item.name), 1),
            _createElementVNode("td", null, _toDisplayString(item.barrier_title), 1),
            _createElementVNode("td", null, [
              _createTextVNode(_toDisplayString(item.manufacture.title) + " ", 1),
              (item.type)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(item.type), 1)
            ]),
            _createElementVNode("td", _hoisted_4, [
              (item.actions.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Not connected to any Flatflow property yet "))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.actions, (action) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: action.id,
                  class: "smart-devices__actions"
                }, [
                  (action.value === 'open')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "smart-devices__button second-button",
                        onClick: ($event: any) => (_ctx.openDevice(item.id))
                      }, _toDisplayString(action.title), 9, _hoisted_6))
                    : _createCommentVNode("", true),
                  (action.value === 'close')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "smart-devices__button second-button",
                        onClick: ($event: any) => (_ctx.closeDevice(item.id)),
                        disabled: item.locked
                      }, _toDisplayString(action.title), 9, _hoisted_7))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("td", _hoisted_8, [
              _createVNode(_component_More),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('message.dashboard.barrier_title')), 1),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(item.barrier_title), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('message.dashboard.device_type')), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createTextVNode(_toDisplayString(item.manufacture.title) + " ", 1),
                      (item.type)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, "-"))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(item.type), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.actions, (action) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: action.id,
                      class: "smart-devices__actions"
                    }, [
                      (action.value === 'open')
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "smart-devices__button second-button",
                            onClick: ($event: any) => (_ctx.openDevice(item.id))
                          }, _toDisplayString(action.title), 9, _hoisted_18))
                        : _createCommentVNode("", true),
                      (action.value === 'close')
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            class: "smart-devices__button second-button",
                            onClick: ($event: any) => (_ctx.closeDevice(item.id)),
                            disabled: item.locked
                          }, _toDisplayString(action.title), 9, _hoisted_19))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}